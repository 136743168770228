<template>
    <div>
        <el-page-header @back="backPrivious" content="标准色库"></el-page-header>
        <div class="banner">
            <img src="@/assets/banner.png" alt="">
        </div>
        <el-breadcrumb style="padding: 20px 0;" class="f-c-e" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">
                <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                <span> 当前位置： 首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>色彩库</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="height: 1px;width: 100%;background: #989899;opacity: 0.08;"></div>
        <div class="" style="padding: 50px 0 50px 0;">
            <div class="options">
                <span style="margin-right: 30px;" @click="select = true,getDate()"
                    :class="select == true ? 'pitchOn' : 'NopitchOn'">我的调色板</span>
                <span @click="select = false,getDate()" :class="select == false ? 'pitchOn' : 'NopitchOn'">色库选色</span>
            </div>
            <div class="flex" v-show="select">
                <!-- 调色板 -->
                <div class="colorpalette">
                    <chrome-picker style="height: 100%; width: 100%;" v-model="colors" @input="updateValue"></chrome-picker>
                </div>
                <!-- 颜色板 -->
                <div class="colorplate" style="">
                    <div class="colorplatetitle f-c-b">
                        <span>#</span>
                        <div class="colorText">{{ picker_color ? picker_color.split('#')[1] : '' }}</div>
                        <img src="@/assets/copy.png" alt="" @click="copy()">
                    </div>
                    <div v-if="picker_color" class="color" :style="{ backgroundColor: picker_color }"></div>
                    <div v-else class="color" style="border: 1px dashed;"></div>
                    <div @click="similaColors()" class="btn">相近色搜索</div>
                </div>

            </div>
            <div class="flex" v-show="!select">
                <!-- 选择色库 -->
                <div class="selectivecolorlibrary">
                    <div class="title">选择色库</div>
                    <div class="color_list">
                        <div class="item1">{{ colorLibraryName }}</div>
                    </div>
                    <div class="btn" @click="dialogVisible = true">更多色库<i class="el-icon-arrow-right"></i></div>
                </div>
                <!-- 搜索颜色 -->
                <div class="searchcolor">
                    <div class="title">搜索颜色</div>
                    <div class="options">
                        <span style="margin-right: 30px;" @click="ColorDetails.colorState = 1"
                            :class="ColorDetails.colorState == 1 ? 'pitchOn' : 'NopitchOn'">搜名称</span>
                        <span @click="ColorDetails.colorState = 2"
                            :class="ColorDetails.colorState == 2 ? 'pitchOn' : 'NopitchOn'">搜编号</span>
                    </div>
                    <el-input v-if="ColorDetails.colorState == 1" v-model="ColorDetails.colorSearch"
                        placeholder="请输入名称"></el-input>
                    <el-input v-else-if="ColorDetails.colorState == 2" v-model="ColorDetails.colorSearch"
                        placeholder="请输入编号"></el-input>
                    <div class="btnBox f-c-a">
                        <div @click="search()" class="SearchBtn">搜索</div>
                        <div class="ResetBtn" @click="reset()">重置</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="colorList">
            <div class="title">
                <span>颜色列表</span>
                <span>（{{ colorLibraryName }}）</span>
            </div>
            <div v-show="tipColorName && colorList.length != 0" class="tip">已搜索到与“{{ tipColorName }}”相似颜色{{ total }}条</div>
            <div v-if="colorList.length !== 0" class="color_list f-w">
                <div class="color_item" v-for="(item, index) in colorList" :key="index" @click="previewColor(item)">
                    <div class="colorblock" :style="{ backgroundColor: `#${item.rgbValue}` }"></div>
                    <div class="color_text f-c-b">
                        <div>{{ item.colorName }}</div>
                        <div>{{ item.colorNumber }}</div>
                    </div>
                    <div v-if="item.diffV" class="color_text2 f-c-b">
                        <div>色差值：ΔE*ab {{ item.diffV }}</div>
                        <div>相似度：{{ (100 - (item.diffV * 3)).toFixed(2) }}%</div>
                    </div>
                    <div v-if="isShowBtn" class="contrastbtn" @click.stop="Contrast(item)">
                        <span>颜色对比 <i class="el-icon-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div v-else class="f-fr-jc empty">
                <img src="@/assets/loading.png" alt="">
                <span>未搜索到相近颜色</span>
            </div>
        </div>
        <Pagination v-if="colorList && colorList.length" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
            :layout="layout" @pagination="fetchData" />
        <el-dialog title="色库" :visible.sync="dialogVisible" width="1280px">
            <div class="dialogList f-w">
                <div class="dialogItem" v-for="(item, index) in colorLibraryList" :key="index"
                    @click="selectLibraryList(item)">
                    {{ item.csName }}
                </div>
            </div>
        </el-dialog>
        <el-dialog title="标准色值预览" :visible.sync="PreviewVisible" width="30%" center>
            <div class="f-fr-jc PreviewVisible">
                <div class="bgbox" :style="{ backgroundColor: `${PreviewObj.rgbValue}` }"></div>
                <div class="colorplatetitle2 f-c-b">
                    <div class="colorText">{{ PreviewObj.colorNumber }}</div>
                    <img src="@/assets/copy.png" alt="" @click="copy2()">
                </div>
                <span>{{ PreviewObj.colorName }}</span>
                <div class="tip">色彩预览会因终端设备的显示效果产生差异仅供参考使用</div>
            </div>
        </el-dialog>
        <Dialog :title="'颜色对比'" width="810px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog">
            <div class="f-cl-c-c">
                <div class="contrastbox flex">
                    <div class="f-c-c" style="width: 50%;border-radius: 30px 0 0 30px;"
                        :style="{ 'background': '#'+colorDetails.rgbValue }">
                        <div class="contrastitem">
                            搜索结果
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;border-radius: 0 30px 30px 0;"
                        :style="{ 'background': picker_color }">
                        <div class="contrastitem">
                            选择颜色
                        </div>

                    </div>

                </div>
                <div class="contrasttext flex">
                    <div style="width: 50%;height: 40px;">
                        <div class="textitem">
                            {{ colorDetails.colorNumber }}
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;height: 40px;">
                        <div class="textitem">
                            {{ picker_color }}
                        </div>
                    </div>
                </div>
                <div v-if="colorDetails.colorNumber" class="contrasttext flex">
                    <div style="width: 50%;">
                        <div class="textitem3">
                            {{ colorDetails.colorName }}
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;">
                        <div class="textitem3">
                            
                        </div>
                    </div>
                </div>
                <div v-if="colorDetails.diffV" class="contrasttext flex">
                    <div style="width: 50%;height: 40px;">
                        <div class="textitem2">
                            色差值：ΔE*ab{{ colorDetails.diffV }}
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;height: 40px;">
                        <div class="textitem2">
                            (相似度：{{ (100 - (colorDetails.diffV * 3)).toFixed(2) }}%)
                        </div>
                    </div>
                </div>
                <div class="tip">
                    色彩预览会因终端设备的显示效果产生差异 仅供参考使用。
                </div>
            </div>

        </Dialog>
    </div>
</template>
<script>

import CryptoJS from '@/utils/DES.js';
import RGB2Lab from '@/utils/RGB2Lab';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { Chrome } from "vue-color";
export default {
    name: 'ColourLibrary',
    mixins: [PaginationRetention],
    components: {
        "chrome-picker": Chrome
    },
    data() {
        return {
            select: true,
            picker_color: '#000000',
            input: '',
            ColorDetails: {
                csId: 1,
                colorSearch: '',
                labL: '',
                labA: '',
                labB: '',
                colorState: 1,
                page: 1,
                size: 10
            },
            colorList: [],
            tipColorName: '',
            colorLibraryList: [],
            colorLibraryName: '',
            dialogVisible: false,
            PreviewVisible: false,
            PreviewObj: {

            },
            colors: {
                color: "",
                hex: '',
                rgba: {
                    r: '',
                    g: '',
                    b: '',
                    a: '',
                },
                // a: '',
            },
            colorDetails:{},
            dialogShow:false,
            isShowBtn: false,
        }
    },
    created() {
        // this.getDate();
        this.findSearchColorLibraryList();
    },
    methods: {
        // 获取色库列表
        async findSearchColorLibraryList() {
            await this.$http.findSearchColorLibraryList({
                csName: "",
                page: 1,
                size: 10
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    this.colorLibraryList = records;
                    this.colorLibraryName = records[0].csName;
                }
            })
        },
        // 
        getDate() {
            let colorSearch = this.$getStorage('ColorSearchValue') ?? '',
                colorState = this.$getStorage('ColorStateValue') ?? '',
                labValue = this.$getStorage('labValue') ?? '';

            this.findSearchColorDetailsList(this.paging, this.pagingSize, colorSearch, colorState, labValue);
        },
        async findSearchColorDetailsList(page = this.page, size = this.size, colorSearch, colorState, labValue) {
            await this.$http.findSearchColorDetailsList({
                csId: 1,
                colorSearch,
                labL: labValue ? labValue.labL : '',
                labA: labValue ? labValue.labA : '',
                labB: labValue ? labValue.labB : '',
                colorState,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach(item => {
                        item.rgbValue = CryptoJS.decrypt(item.rgbValue)
                    })
                    this.colorList = records;
                    this.currentPage = res.data.current;
                    this.total = res.data.total;
                }
            })
        },
        // 搜索相近颜色
        async similaColors() {
            let that = this;
            if (that.picker_color) {
                let { R, G, B } = RGB2Lab.hexToRgba(that.picker_color);
                let labValue = RGB2Lab.RGBToXYZ(R, G, B);
                labValue.labL = labValue.labL * 13 + 123;
                labValue.labA = labValue.labA * 17 + 456;
                labValue.labB = labValue.labB * 19 + 789;
                this.$setStorage('ColorStateValue', 3)
                this.$setStorage('labValue', labValue)
                this.findSearchColorDetailsList(1, this.size, '', 3, labValue);
                this.isShowBtn = true;
            } else {
                that.$common.message('先请在调色板选择颜色', 'warning')
            }


        },
        // 复制
        copy() {
            let that = this;
            that.$copyText(this.picker_color.split('#')[1]).then(
                function (e) {
                    console.log(e)
                    that.$common.message('复制成功', 'success')
                },
                function (e) {
                    console.log(e)
                    that.$common.message('复制失败', 'error')
                }
            );
        },
        copy2() {
            let that = this;
            that.$copyText(this.PreviewObj.colorNumber).then(
                function (e) {
                    console.log(e)
                    that.$common.message('复制成功', 'success')
                },
                function (e) {
                    console.log(e)
                    that.$common.message('复制失败', 'error')
                }
            );
        },
        // 搜索
        async search() {
            let data = this.ColorDetails;
            this.$setStorage('ColorSearchValue', data.colorSearch)
            this.$setStorage('ColorStateValue', data.colorState)
            this.findSearchColorDetailsList(1, this.size, data.colorSearch, data.colorState);
            this.isShowBtn = false;
        },
        // 重置
        reset() {
            this.$removeStorage('ColorSearchValue')
            this.$removeStorage('ColorStateValue')
            this.$removeStorage('labValue')
            this.$removeStorage('fetchDataSize')
            this.$removeStorage('fetchDataPage')
            // this.picker_color = '';
            this.tipColorName = '';
            this.ColorDetails = {
                csId: 1,
                colorSearch: '',
                labL: '',
                labA: '',
                labB: '',
                colorState: 1,
                page: 1,
                size: 10
            }
            this.size = 10;
            this.page = 1;
            this.search();
        },
        selectLibraryList(e) {
            this.colorLibraryName = e.csName;
            this.dialogVisible = false;
        },
        previewColor(e) {
            this.PreviewObj = {
                colorName: e.colorName,
                colorNumber: e.colorNumber,
                rgbValue: `#` + e.rgbValue
            }
            this.PreviewVisible = true;
        },
        updateValue(value) {
            console.log(value.hex)
            this.picker_color = value.hex;
        },
        Contrast(data) {
            console.log(data)
            this.dialogShow = true;
            this.colorDetails = data;
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
    },
    beforeDestroy() {
        this.$removeStorage('ColorSearchValue')
        this.$removeStorage('ColorStateValue')
        this.$removeStorage('labValue')
        this.$removeStorage('fetchDataSize')
        this.$removeStorage('fetchDataPage')
    }
}

</script>

<style lang="less" scoped>
.banner {
    height: 100px;
    width: 1280px;
    margin-bottom: 40px;

    img {
        width: 100%;
        height: 100%;
    }
}

.el-breadcrumb ::v-deep .el-breadcrumb__inner {
    color: #666666 !important;
    font-weight: 400 !important;
    font-size: 14px;
}

.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
    color: #0363FA !important;
    font-weight: 400 !important;
    font-size: 14px;
}

.options {
    padding: 10px 0;
    font-size: 18px;
    margin-bottom: 20px;
    cursor: pointer;

    .pitchOn {
        padding: 5px 0;
        color: #000000;
        border-bottom: 2px solid #000000;
    }

    .NopitchOn {
        padding: 5px 0;
        color: #999999;
    }
}

.colorplate {
    margin-right: 50px;

    .colorplatetitle {
        width: 140px;

        span {
            font-size: 24px;
            color: #000000;
            font-weight: 400;
        }

        .colorText {
            width: 80px;
            height: 32px;
            color: #000000;
            font-size: 20px;
            background-color: #F1F1F1;
            border-radius: 10px;
            line-height: 32px;
            text-align: center;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .color {
        margin-top: 20px;
        height: 140px;
        width: 140px;
        border-radius: 10px;
        // background-color: #f39b33;
    }

    .btn {
        margin-top: 20px;
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: #2971FA;
        border-radius: 10px;
        background-color: #EFF4FA;
        cursor: pointer;
    }
}

.colorpalette {
    margin-right: 50px;
    // border: 1px solid red;
    width: 250px;
    height: 250px;

    .title {
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 30px;
    }

    /deep/.vc-chrome-toggle-btn {
        display: none;
    }
}

.selectivecolorlibrary {
    margin-right: 80px;

    .title {
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 40px;
    }

    .color_list {
        font-size: 16px;

        .item1 {
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            border-radius: 10px;
            width: 250px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: #FFFFFF;
        }

        .item2 {
            background-color: #F8F8F8;
            border-radius: 10px;
            width: 250px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #000000;
            margin-top: 10px;
        }

    }

    .btn {
        margin-top: 10px;
        color: #FFFFFF;
        font-size: 16px;
        height: 40px;
        width: 100px;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        border-radius: 10px;
        cursor: pointer;
    }
}

.searchcolor {
    .title {
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .options {
        padding: 10px 0;
        font-size: 18px;
        margin-bottom: 20px;
        cursor: pointer;

        .pitchOn {
            padding: 5px 0;
            color: #000000;
            border-bottom: 2px solid #000000;
        }

        .NopitchOn {
            padding: 5px 0;
            color: #999999;
        }
    }

    /deep/ .el-input__inner {
        height: 55px;
        width: 435px;
        padding: 0 10px;
        background-color: #F8F8F8;
        border: none;
    }

    .btnBox {
        margin-top: 55px;

        .SearchBtn {
            color: #FFFFFF;
            font-size: 16px;
            height: 40px;
            width: 125px;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            border-radius: 10px;
            cursor: pointer;
        }

        .ResetBtn {
            height: 40px;
            width: 125px;
            line-height: 40px;
            color: #0873F4;
            text-align: center;
            background: #f8f8f8;
            border-radius: 10px;
            cursor: pointer;
        }
    }

}

.colorList {
    .title {
        color: #000000;

        span:first-of-type {
            font-size: 24px;
        }

        span:last-of-type {
            font-size: 16px;
        }
    }

    .tip {
        margin-top: 20px;
        font-size: 16px;
        color: #999999;
    }

    .color_list {
        margin-top: 30px;

        .color_item {
            padding: 15px;
            width: 215px;
            // height: 120px;
            background: #F8F8F8;
            border-radius: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            .colorblock {
                width: 215px;
                height: 60px;
                background: #FFD8A9;
                border-radius: 10px;
            }

            .color_text {
                margin-top: 15px;
                font-size: 16px;
                color: #333333;
            }

            .color_text2 {
                margin-top: 15px;
                font-size: 12px;
                color: #333333;
            }

            .contrastbtn {
                background-color: #FFF;
                text-align: center;
                height: 30px;
                width: 90px;
                line-height: 30px;
                border-radius: 15px;
                margin: 20px auto 0;
                padding: 2px;
                font-size: 14px;
            }
        }
    }

    .empty {
        margin-top: 65px;

        img {
            width: 20px;
            height: 20px;
        }

        span {
            margin-top: 15px;
            color: #999999;
            font-size: 16px;
        }

        .btn {
            margin-top: 20px;
            width: 80px;
            height: 40px;
            line-height: 40px;
            color: #FFFFFF;
            text-align: center;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            border-radius: 10px;
            cursor: pointer;
        }
    }
}

.dialogList {
    height: 500px;
    padding: 0 70px;

    .dialogItem {
        padding: 10px 15px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        background: #F8F8F8;
        border-radius: 10px;
        cursor: pointer;
    }
}

.PreviewVisible {
    .bgbox {
        border-radius: 10px;
        height: 200px;
        width: 300px;
    }

    .colorplatetitle2 {
        margin-top: 20px;

        .colorText {
            height: 32px;
            color: #000000;
            font-size: 20px;
            background-color: #F1F1F1;
            border-radius: 10px;
            line-height: 32px;
            text-align: center;
            margin-right: 10px;
            padding: 5px;
        }

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    span {
        margin-top: 10px;
    }

    .tip {
        margin-top: 20px;
    }
}

/deep/ .el-dialog {
    border-radius: 10px;
}
.commonDialog {
    /deep/ .el-dialog {
        border-radius: 20px;

        /deep/ .el-dialog__title {
            font-size: 36px !important;
            font-family: HarmonyOS Sans SC !important;
            font-weight: 500 !important;
            color: #000000 !important;
        }
    }

    /deep/.el-dialog__header {
        padding: 30px;
    }

    /deep/ .el-dialog__title {
        margin-top: 40px !important;
        font-size: 36px !important;
        font-family: HarmonyOS Sans SC !important;
        font-weight: 500 !important;
        color: #000000 !important;
    }

    .product {
        width: 400px;

        .tit {
            font-size: 18px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 10px;
        }

        .productbox {
            height: 75px;
            border-radius: 30px;
            border: 1px solid #E2E2E2;
            padding: 24px 30px;

            .elimg {
                height: 75px;
                width: 75px;
                border-radius: 20px;
            }

            .productinfo {
                width: 70%;

                .productname {
                    width: 75%;
                    font-size: 23px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #000000;
                }

                .cornermark {
                    border-radius: 5px;
                    background: #E6F1FE;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 12px;
                    color: #699EF2;
                    padding: 3px;
                }

                .productNumber {
                    font-size: 17px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #999999;
                }


                .priceBox {
                    font-weight: 500;
                    color: #FF4E36;

                    .unit {
                        font-size: 14px;
                    }

                    .price {
                        font-size: 18px;
                        font-weight: 500;
                    }

                }


            }
        }
    }

    .contrastbox {
        width: 400px;
        height: 150px;
        border-radius: 30px;
        margin-top: 34px;

        .contrastitem {
            background: #FFF;
            width: 115px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #333333;
            border-radius: 18px;
        }
    }

    .contrasttext {
        width: 400px;
        margin-top: 20px;

        .textitem {
            margin: 0 auto;
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 15px;
            background: #EFF2F7;
            color: #333;
        }

        .textitem2 {
            margin: 0 auto;
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 15px;
            color: #666666;
        }
        .textitem3 {
            margin: 0 auto;
            width: 150px;
            text-align: center;
            color: #666666;
        }
    }

    .tip {
        width: 340px;
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 30px;
    }

}
</style>